var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"logo",on:{"click":function($event){return _vm.toPage('/')}}},[(_vm.color == 'gold')?_c('img',{staticClass:"goldimg",attrs:{"src":require("@image/logogold.png"),"alt":""}}):(_vm.color == 'white')?_c('img',{staticClass:"normalimg",attrs:{"src":require("@image/logowhite.png"),"alt":""}}):(_vm.color == 'one')?_c('img',{staticClass:"normalimg",attrs:{"src":require("@image/logogray.png"),"alt":""}}):_c('img',{staticClass:"normalimg",attrs:{"src":require("@image/logoBlock.png"),"alt":""}})]),_c('div',{staticClass:"mysection",class:{
      myBlack: _vm.listColor == 'black' || _vm.listColor == 'one',
      myWhite: _vm.listColor != 'black',
      mypos1: _vm.isIndex,
      mypos: !_vm.isIndex,
      exc:_vm.exc
    }},[_c('div',{on:{"mousemove":function () {
          this$1.isFocus = true;
        },"mouseleave":function () {
          this$1.isFocus = false;
        }}},[(_vm.color == 'white')?_c('img',{attrs:{"src":require("@image/section.png")}}):_vm._e(),(_vm.color == 'one')?_c('img',{attrs:{"src":require("@image/section2.png")}}):_vm._e(),(_vm.color == 'black')?_c('img',{attrs:{"src":require("@image/section1.png")}}):_vm._e(),(_vm.color == 'gold')?_c('img',{attrs:{"src":require("@image/section3.png")}}):_vm._e()]),_c('ul',{class:{ ulHover: _vm.isFocus },on:{"mousemove":function () {
          this$1.isFocus = true;
        },"mouseleave":function () {
          this$1.isFocus = false;
        }}},[_c('li',{on:{"click":function($event){return _vm.toPage('about')}}},[_vm._v("品牌简介")]),_c('li',{on:{"click":function($event){return _vm.toPage('group')}}},[_vm._v("关于集团")]),_c('li',{on:{"click":function($event){return _vm.toPage('joinus')}}},[_vm._v("加入我们")]),_c('li',{on:{"click":function($event){return _vm.toPage('contact')}}},[_vm._v("联系我们")]),_c('li',{on:{"click":function($event){return _vm.toPage('news')}}},[_vm._v("工业旅游")])])]),(_vm.topShow)?_c('div',{staticClass:"topLists",class:{
      topBlack: _vm.listColor == 'black',
      topWhite: _vm.listColor == 'white',
      topGold: _vm.listColor == 'gold',
      topOne: _vm.listColor == 'one',
      lampoEX: _vm.title == 'lampolook',
    }},[_c('div',[_vm._v(" 大片 / LOOK "),(_vm.listColor == 'black')?_c('img',{staticClass:"array",attrs:{"src":require("@image/right1.png")}}):(_vm.listColor == 'one')?_c('img',{staticClass:"array",attrs:{"src":require("@image/right2.png")}}):(_vm.listColor == 'gold')?_c('img',{staticClass:"array",attrs:{"src":require("@image/right3.png")}}):_vm._e()]),_c('div',{staticClass:"lists"},_vm._l((_vm.titleLists),function(item,index){return _c('div',{key:index,staticClass:"listsItem",class:{ active: index == _vm.nowIndex,lampoEX:_vm.title == 'lampolook' },on:{"click":function($event){return _vm.changeYear(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }