<template>
  <div class="main">
    <div class="logo" @click="toPage('/')">
      <img
        src="@image/logogold.png"
        alt=""
        v-if="color == 'gold'"
        class="goldimg"
      />
      <img
        src="@image/logowhite.png"
        alt=""
        v-else-if="color == 'white'"
        class="normalimg"
      />
      <img
        src="@image/logogray.png"
        alt=""
        v-else-if="color == 'one'"
        class="normalimg"
      />
      <img src="@image/logoBlock.png" alt="" v-else class="normalimg" />
    </div>
    <div
      class="mysection"
      :class="{
        myBlack: listColor == 'black' || listColor == 'one',
        myWhite: listColor != 'black',
        mypos1: isIndex,
        mypos: !isIndex,
        exc:exc
      }"
    >
      <div
        @mousemove="
          () => {
            this.isFocus = true;
          }
        "
        @mouseleave="
          () => {
            this.isFocus = false;
          }
        "
      >
        <img src="@image/section.png" v-if="color == 'white'" />
        <img src="@image/section2.png" v-if="color == 'one'" />
        <img src="@image/section1.png" v-if="color == 'black'" />
        <img src="@image/section3.png" v-if="color == 'gold'" />
      </div>

      <ul
        :class="{ ulHover: isFocus }"
        @mousemove="
          () => {
            this.isFocus = true;
          }
        "
        @mouseleave="
          () => {
            this.isFocus = false;
          }
        "
      >
        <li @click="toPage('about')">品牌简介</li>
        <li @click="toPage('group')">关于集团</li>
        <li @click="toPage('joinus')">加入我们</li>
        <li @click="toPage('contact')">联系我们</li>
        <li @click="toPage('news')">工业旅游</li>
      </ul>
    </div>
    <div
      class="topLists"
      :class="{
        topBlack: listColor == 'black',
        topWhite: listColor == 'white',
        topGold: listColor == 'gold',
        topOne: listColor == 'one',
        lampoEX: title == 'lampolook',
      }"
      v-if="topShow"
    >
      <div>
        大片 / LOOK
        <img
          src="@image/right1.png"
          v-if="listColor == 'black'"
          class="array"
        />
        <img
          src="@image/right2.png"
          v-else-if="listColor == 'one'"
          class="array"
        />
        <img
          src="@image/right3.png"
          v-else-if="listColor == 'gold'"
          class="array"
        />
      </div>
      <div class="lists">
        <div
          v-for="(item, index) in titleLists"
          :key="index"
          class="listsItem"
          @click="changeYear(index)"
          :class="{ active: index == nowIndex,lampoEX:title == 'lampolook' }"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "mySection",
  components: {},
  props: {
    isIndex: {
      type: Boolean,
      default() {
        return false;
      },
    },
    listColor: {
      type: String,
      default() {
        return "black";
      },
    },
    exc:{
      type: String,
      default() {
        return "";
      },
    },
    topShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    color: {
      type: String,
      default() {
        return "white";
      },
    },
    titleLists: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      isFocus: false,
      nowIndex: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    toPage(e) {
      this.jump(e);
    },
    changeYear(e) {
      this.$emit("changeYear", e);
      this.nowIndex = e;
    },
  },
};
</script>
<style scoped>
.main {
  font-family: "header";
  font-weight: 700;
}
.logo {
  cursor: pointer;
}
.logo .normalimg {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 5px;
  left: 25px;
  z-index: 9;
}
.logo .goldimg {
  height: 38px;
  position: fixed;
  top: 8px;
  left: 30px;
  z-index: 9;
}
.mysection {
  top: 18px;
  left: 75px;
  z-index: 9;
}
.mypos {
  position: fixed;
}
.mypos1 {
  position: fixed;
}
.mysection img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.ulHover {
  opacity: 1 !important;
}
.mysection ul {
  padding-top: 20px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.mysection li {
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  font-weight: 600;
}

.myWhite {
  color: white;
}
.myBlack {
  color: #282828;
}

.exc{
  color: #ffffff;
}
.mysection li:hover {
  opacity: 0.6;
}
.topLists {
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 8;
  transition: 0.5s ease-in-out;
  display: flex;
}
.topBlack {
  color: #000000;
}
.topBlack .lists {
  color: #000000;
}
.topBlack .lists .active {
  color: #a7a8a9;
  font-size: 15px;
}
.topWhite {
  color: #fff;
}
.topWhite .lists {
  color: rgba(255, 255, 255, 0.5);
}
.topWhite .lists .active {
  color: #fff;
  font-size: 15px;
}
.topOne {
  color: #a7a8a9;
}
.topOne .lists {
  color: #a7a8a9;
}
.topOne .lists .active {
  color: #282828;
  font-size: 15px;
}
.topGold {
  color: #bea681;
}
.topGold .lists {
  color: #bea681;
}
.topGold .lists .active {
  color: #8f6930;
  font-size: 15px;
}
.topLists{
  background: rgba(255, 255, 255,1);
}
/* .topLists .lists {
  opacity: 1;
} */
.topLists > div:first-child {
  position: absolute;
  top: 20px;
  left: 15%;
  font-size: 15px;
}
.icon-you {
  font-size: 5px;
}
.topLists .lists {
  position: absolute;
  left: 24%;
  top: 21px;
  height: 80px;
  display: flex;
  font-size: 14px;
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.topLists .lists .listsItem {
  height: 25px;
  cursor: pointer;
  width: 120px;
  text-align: center;
}
.array {
  height: 12px;
  margin-top: -2px;
  margin-left: 10px;
}

.lampoEX{
  background-color: #1b2244;
  color: #ffffff;
}
</style>